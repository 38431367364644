import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  isCollapse: boolean;
  ignoreLs?: boolean;
};

export const MoneyWire = ({ isCollapse, ignoreLs = false }: Props) => {
  if (isCollapse) return null;

  const navigateToExternalResource = () => {
    window.open('https://remesas.cabal-app.com', '_blank');
  };

  return (
    <div>
      <Card
        style={{
          background: `#9400F4`,
          position: 'relative',
          borderRadius: ignoreLs ? 16 : 0,
          cursor: 'pointer',
        }}
      >
        <CardActions
          style={{ padding: 16 }}
          onClick={navigateToExternalResource}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Stack spacing={1} style={{ pointerEvents: 'none' }}>
                <Typography
                  style={{
                    fontSize: ignoreLs ? 21 : isCollapse ? 12 : 14,
                    color: '#ece5dd',
                    fontWeight: 'bold',
                  }}
                >
                  Recibe remesas
                </Typography>
                <Typography
                  style={{
                    fontSize: ignoreLs ? 21 : isCollapse ? 12 : 14,
                    color: '#ece5dd',
                  }}
                >
                  Directo a tu tarjeta de débito salvadoreña
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={4} style={{ justifyContent: 'flex-end' }}>
              <div style={{ position: 'relative', width: 75, height: 75 }}>
                <div
                  style={{
                    display: 'flex',
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    top: -8,
                    left: -8,
                  }}
                >
                  <img
                    src="/assets/usa_flag.png"
                    alt="money wire animation"
                    width={24}
                    height={24}
                    style={{ display: 'block' }}
                  />
                </div>
                <img
                  src="/assets/moneywire.gif"
                  alt="money wire animation"
                  width={75}
                  height={75}
                  style={{ display: 'block' }}
                />
                <div
                  style={{
                    display: 'flex',
                    width: 24,
                    height: 24,
                    position: 'absolute',
                    bottom: -8,
                    right: -8,
                  }}
                >
                  <img
                    src="/assets/sv_flag.png"
                    alt="money wire animation"
                    width={24}
                    height={24}
                    style={{ display: 'block' }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};
